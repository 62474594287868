import React from 'react';
import styled from 'styled-components';
import FlaskLogoSrc from '../../../images-v2/svg/icon_flask.svg';
import ContentList from './content_list';
import '../../../styles/foundation-v2.css';

const captionWidthPc = '928px';
const captionWidthSp = '94%';

const Root = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 60vh;
  margin: 0 auto;

  @media (max-width: 1023px) {
    margin-top: 137px;
    margin-bottom: 100px;
    width: ${captionWidthSp};
  }
  @media (min-width: 1024px) {
    margin-top: 223px;
    margin-bottom: 80px;
    width: ${captionWidthPc};
  }
`;

const BodyWrapper = styled.div`
  z-index: 1;

  @media (max-width: 1024px) {
    width: calc(100% - 3.2px);
  }
`;

const CaptionLeftAlign = styled.div`
  display: flex;
  align-items: baseline;
  @media (max-width: 1023px) {
    margin-bottom: 32px;
  }
  @media (min-width: 1024px) {
    margin-bottom: 56px;
  }
`;

const Title = styled.h2`
  margin-left: 20px;
  display: inline-block;

  @media (max-width: 1023px) {
    font-size: 20px;
  }
  @media (min-width: 1024px) {
    font-size: 24px;
  }
`;

const TitleInner = styled.div`
  display: flex;
`;

const IndexContent = ({ cmsData }) => {
  return (
    <Root>
      <BodyWrapper>
        <CaptionLeftAlign>
          <h1 className="section_title_left">読み物</h1>
          <Title>
            <TitleInner>
              <img className="FlaskLogo" src={FlaskLogoSrc} alt="" />
              <p className="grad_txt section_title_right">Regnio Lab</p>
            </TitleInner>
          </Title>
        </CaptionLeftAlign>
        <ContentList cmsData={cmsData} />
      </BodyWrapper>
    </Root>
  );
};

export default IndexContent;
