import React, { useState } from 'react';
import ContentsCms from '../constants-v2/page-contents/contents-cms';
import PageRoot from '../components/common-components/templates/page-root-v2';
import IndexContent from '../components/content-components-v2/search';
import metaData from '../constants-v2/meta-data';
import SEOHeaderV2 from '../components/common-components/templates/page-root-v2/seo-v2';

const ContentsPage = () => {
  const [cmsData] = useState(ContentsCms());
  const isLogoAlwaysVisible = true;

  return (
    <PageRoot
      heroYOffset={100}
      isLogoAlwaysVisible={isLogoAlwaysVisible}
      pageUrl={metaData.urls.contents}
      pageTitle="Regnio Lab"
      ogpImageName="ogp-image.png"
    >
      <SEOHeaderV2 pageTitle="読み物" />
      <IndexContent cmsData={cmsData.allWordpressPost} />
    </PageRoot>
  );
};

export default ContentsPage;
